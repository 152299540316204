import { init } from '@amplitude/analytics-browser';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation, i18n } from 'next-i18next';
import NextApp, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useStore } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { getNovaTranslations, IntlProviderNext, ThemeProvider } from '@indriver/nova';
import { App } from 'app/app';
import { RootState, wrapper } from 'app/store';
import { useDefaultLocale, useSyncStoreToCookie } from 'common/lib/hooks';
import { AfScript, FbPixelScript, GtmScript, HideCmp } from 'common/lib/scripts';
import * as Domain from 'entities/domain';
import { OgGraph, SeoDefault } from 'entities/seo';
import * as Seo from 'features/seo';
import 'normalize.css';
import '@indriver/yrel/dist/fonts/noto.css';
import '@indriver/yrel/dist/fonts/coolvetica.css';
import '@indriver/yrel/dist/theme-light/theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'app/embla.css'; // TODO: move embla.css to the component itself
import 'app/toastify.css';
const {
  publicRuntimeConfig
} = getConfig();
i18n?.init();
const NOVA_LOCALES = ['en', 'es-MX', 'kk', 'pt-BR', 'ru'];
function MyApp({
  Component,
  pageProps
}: AppProps) {
  const queryClient = React.useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  }), []);
  const novaDictionary = React.useMemo(() => Object.fromEntries(NOVA_LOCALES.map(locale => [locale, i18n?.getResourceBundle(locale, 'nova')])), []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store: any = useStore();
  useSyncStoreToCookie('cityId', (state: RootState) => state.currentCity?.id);
  React.useEffect(() => {
    if (process.env.DISABLE_ANALYTICS !== 'true') {
      // Amplitude analytics init
      init(publicRuntimeConfig.AMPLITUDE_API_KEY);
    }
  }, []);
  const dir = i18n?.dir();
  React.useEffect(() => {
    if (dir) {
      document.documentElement.dir = dir;
    }
  }, [dir]);
  useDefaultLocale(pageProps.domainConfig);
  return <>
            <Head>
                {pageProps.canonicalLink && <link rel='canonical' href={pageProps.canonicalLink} />}
                {pageProps.alternateLinks?.map(({
        href,
        hrefLang
      }: Record<string, string>) => <link key={hrefLang} rel='alternate' hrefLang={hrefLang} href={href} />)}
                <link rel='icon' href='/favicon.ico?v=2' sizes='any' />
                <link rel='icon' href='/icon.svg?v=2' type='image/svg+xml' />
                <link rel='apple-touch-icon' href='/apple-touch-icon.png?v=2' />
                <link rel='manifest' href='/manifest.webmanifest' />
                <meta name='viewport' content='width=device-width, initial-scale=1' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='application-name' content='inDrive' />
                <meta name='apple-mobile-web-app-title' content='inDrive' />
                <meta name='theme-color' content='#fff' />
                <meta name='msapplication-navbutton-color' content='#fff' />
                <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
                <meta name='msapplication-starturl' content='/' />
                {pageProps.domainConfig.countryIso === 'kz' && <HideCmp />}
            </Head>
            <App>
                {process.env.DISABLE_ANALYTICS !== 'true' && <>
                        <GtmScript />
                        <FbPixelScript />
                        <AfScript />
                    </>}
                <SeoDefault />
                <OgGraph url={pageProps.canonicalLink} />
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <GoogleReCaptchaProvider reCaptchaKey={publicRuntimeConfig?.GOOGLE_RECAPTCHA_V3_SITE_KEY ?? ''} scriptProps={{
            defer: true,
            appendTo: 'body'
          }}>
                            <Domain.ContextProvider value={pageProps.domainConfig}>
                                <IntlProviderNext dictionary={novaDictionary} locale={i18n?.resolvedLanguage}>
                                    <PersistGate loading={null} persistor={store.persistor}>
                                        {() => <ThemeProvider autoDetectTheme={false} wrapped={false}>
                                                <Component {...pageProps} />
                                            </ThemeProvider>}
                                    </PersistGate>
                                </IntlProviderNext>
                            </Domain.ContextProvider>
                        </GoogleReCaptchaProvider>
                    </Hydrate>
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </App>
            <ToastContainer enableMultiContainer containerId='main' />
            <ToastContainer enableMultiContainer containerId='mixed-model' />
        </>;
}
MyApp.getInitialProps = async (appCtx: AppContext) => {
  const {
    pageProps
  } = await NextApp.getInitialProps(appCtx);
  return {
    pageProps: {
      ...pageProps,
      ...getNovaTranslations(),
      ...Seo.getMetaLinks(appCtx),
      ...Domain.fromAppContext(appCtx)
    }
  };
};
export default appWithTranslation(wrapper.withRedux(MyApp));